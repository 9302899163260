import SVG2CSS from "@jordibosch20/software_tools_package/dist/pages/SVG2CSS.js"
import styles from "./App.module.css";
import { getSVG2CSS } from "@jordibosch20/software_tools_package/dist/utils/svg2css.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <SVG2CSS title={"SVG to CSS Url-Encoder"} f={getSVG2CSS}></SVG2CSS>
      </div>
      <div className={styles.text}>
        <p><strong>Introduction</strong>: In the fast-paced world of web development and design, efficiency and performance are paramount. Our SVG to Data URI Converter stands at the forefront of technological innovation, offering a streamlined solution for transforming SVG graphics into highly efficient Data URIs. This powerful conversion process not only simplifies the integration of crisp, scalable vector graphics into your website's CSS but also significantly enhances site load times and overall user experience. Designed with precision and ease of use in mind, our tool caters to the needs of seasoned developers and creative designers alike, ensuring that high-quality visuals are just a click away.</p>
        <p><strong>Why Choose Our SVG to Data URI Converter?</strong></p>
        <ul>
        <li><strong>Streamlined Workflow</strong>: Convert SVG to Data URI with unmatched ease, integrating graphics directly into CSS without the need for external files. This reduces server requests and speeds up website response times, offering a smoother experience for your users.</li>
        <li><strong>Optimized Performance</strong>: By embedding images directly into your CSS, our tool helps in minimizing HTTP requests, a common cause of lag in web page loading. The result is a significantly faster, more responsive site that keeps users engaged.</li>
        <li><strong>Precision and Quality</strong>: Maintain the integrity and quality of your original SVG graphics. The conversion process ensures that your images remain sharp and scalable, adapting flawlessly to different screen sizes and resolutions.</li>
        <li><strong>Enhanced User Experience</strong>: Faster load times and seamless image integration contribute to a more polished and professional website appearance. This not only improves user satisfaction but also supports higher SEO rankings, as site speed is a key factor in search engine algorithms.</li>
        <li><strong>Security and Privacy</strong>: Our converter processes your SVG files instantly, without storing any data on our servers. This ensures that your proprietary graphics remain secure and confidential throughout the conversion process.</li>
        </ul>
        <p><strong>How It Works</strong></p>
        <ol>
        <li><strong>Input Your SVG Code</strong>: Simply paste your SVG graphics code into our conversion tool.</li>
        <li><strong>Convert with One Click</strong>: Initiate the conversion to generate a Data URI that's ready for CSS integration.</li>
        <li><strong>Copy and Embed</strong>: Copy the generated Data URI and embed it directly into your CSS file as a background image or in any other context that suits your design needs.</li>
        </ol>
        <p><strong>Applications and Benefits</strong></p>
        <p>Ideal for web development projects, UI/UX design, and online content creation, our SVG to Data URI Converter is a versatile tool that enhances the visual dynamics of websites while optimizing performance. It's particularly beneficial for:</p>
        <ul>
        <li><strong>E-commerce Sites</strong>: Improve product displays with high-quality images that load instantly.</li>
        <li><strong>Portfolio Websites</strong>: Showcase your work in the best light with fast-loading, scalable graphics.</li>
        <li><strong>Blogs and News Outlets</strong>: Enhance articles and posts with embedded graphics that don't impact load times.</li>
        </ul>
        <p></p>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;